import alipayCny from "./jpay/images/alipay-cny.jpg";
import ghpgAlipayCny from "./ghpg/images/alipay-cny.jpg";
import ghpgAlipayCny2 from "./ghpg/images/alipay-cny2.jpg";
import ghpgAlipayZZ from "./mtpay/images/alipay-zz.png";

import AlipayLogo1 from "./fakapay/images/AlipayLogo1.png";
import AlipayLogo2 from "./fakapay/images/AlipayLogo2.png";
import AlipayLogo3 from "./fakapay/images/AlipayLogo3.png";
import AlipayLogo4 from "./fakapay/images/AlipayLogo4.png";
import AlipayLogo5 from "./fakapay/images/AlipayLogo5.png";
import AlipayLogo6 from "./fakapay/images/AlipayLogo6.png";
import AlipayLogo7 from "./fakapay/images/AlipayLogo7.png";
import AlipayLogo8 from "./fakapay/images/AlipayLogo8.png";
import AlipayLogo9 from "./fakapay/images/AlipayLogo9.png";
import AlipayLogo10 from "./fakapay/images/AlipayLogo10.png";
import AlipayLogo11 from "./fakapay/images/AlipayLogo11.png";
import AlipayLogo12 from "./fakapay/images/AlipayLogo12.png";
import AlipayLogo13 from "./fakapay/images/AlipayLogo13.png";

import AlipayBlue1 from "./fakapay/images/AlipayBlue1.png";
import AlipayBlue2 from "./fakapay/images/AlipayBlue2.png";
import AlipayOrange from "./fakapay/images/AlipayOrange.png";
import AlipayYellow from "./fakapay/images/AlipayYellow.png";
import wechatPayfaka from "./fakapay/images/wechatpay.png";
import wechatPayG3pay from "./g3pay/images/g3pay.png";
import hupayAlipay from "./hupay/images/hupay-alipay.jpg";
import alipayHkd from "./jpay/images/alipay-hkd.jpg";
import alipayHkd2 from "./jpay/images/alipay-hkd2.jpg";
import cloudPay from "./jpay/images/cloudpay.jpg";
import wechatPay from "./jpay/images/wechatpay.jpg";
import wechatPay2 from "./wechatpay/images/wechat_pay.jpg";
import wechatPay3 from "./wechatpay/images/wechat_pay2.jpg";
import wechat1star from "./hupay/images/wechat1star.jpg";
import wechat2star from "./hupay/images/wechat2star.jpg";
import wechat3star from "./hupay/images/wechat3star.jpg";
import wechat4star from "./hupay/images/wechat4star.jpg";
import wechat5star from "./hupay/images/wechat5star.jpg";
import wechat6star from "./hupay/images/wechat6star.jpg";
import unionPay from "./jpay/images/unionpay.jpg";
import AlipayQr from "./hupay/images/alipay_qr.jpg";
import AlipayQr1 from "./hupay/images/alipay_qr1.jpg";
import AlipayQr2 from "./hupay/images/alipay_qr2.jpg";
import AlipayQr3 from "./hupay/images/alipay_qr3.jpg";
import AlipayQr4 from "./hupay/images/alipay_qr4.jpg";
import spAlipayHKQR from "./spglobalpay/images/sphkqr.jpg";
import spAlipayHKApp from "./spglobalpay/images/sphkapp.jpg";
import spAlipayCNQR from "./spglobalpay/images/spcnqs.jpg";
import spAlipayCNApp from "./spglobalpay/images/spcnapp.jpg";
import bbmsl5in1 from "./bbmslpay/images/nbbmsl5in1.png";
import bbmsl6in1 from "./bbmslpay/images/bbmsl6in1.png";

import mtpayzz from "./ktpay/images/mtpayzz.png";
import tkalipay from "./ktpay/images/alipay.png";
import tkalipay1 from "./ktpay/images/tkpay.png";


import AlipayApp2point from "./cnalipay2/images/alipay2point.jpg";
import AlipayApp3point from "./cnalipay2/images/alipay2point.jpg";
import AlipayApp4point from "./cnalipay2/images/alipay2point.jpg";
import AlipayAppHee from "./heepay/images/heepay.png";
import AlipayApp from "./cnalipay/images/cnalipay.png";
import AlipayApp2p from "./cnalipay/images/cnalipay2p.png";
import AlipayApp5p from "./cnalipay/images/cnalipay5p.png";
import AlipayApp1 from "./hkalipay/images/hkalipay1.jpg";
import Alipay1s from "./hkalipay/images/alipay_1s.jpg";
import Alipay2s from "./hkalipay/images/alipay_2s.jpg";
import Alipay3s from "./hkalipay/images/alipay_3s.jpg";
import star4QR from "./hupay/images/4starQR.jpg";
import p99GashPnt from "./p99/images/p99-gash-points.png";
import p99WebAtm from "./p99/images/p99-webatm.png";
import p99SmartPay from "./p99/images/p99-smart-pay.png";
import p99TWCreditCard from "./p99/images/new_p99-tw-card.png";
import p99UnionPayTWD from "./p99/images/p99-union-pay-twd.jpg";
import p99uptw from "./p99/images/p99-uptw.png"
import UnionPayHKD1 from "./sicpay/images/p99-union-pay-hkd.jpg";
import UnionPayHKD from "./asia/images/union pay hk.jpg";
import asiaunionpay1 from "./asia/images/asiaunionpay.jpg";
import asiaunionpay from "./asia/images/asiaunionpay1.png";
import asiaunionpay5p from "./asia/images/asiaunionpay5p.png";
import asiaunionpay2 from "./asia/images/asiaunionpay2.jpg";
import asiaunionpay3 from "./asia/images/asiaunionpay2.png";
import asiaunionpay4 from "./asia/images/asiaunionpay4.png";
import UnionPayRMB from "./sicpay/images/p99-union-pay.jpg";
import p99CHTelecom from "./p99/images/p99-chunghwa-telecom.png";
import p99epin from "./p99/images/epin.png";
import p99apple from "./p99/images/applepay.png";
import p99google from "./p99/images/googlepay.png";
import p99FET from "./p99/images/p99-fareastone.png";
import p99TWMobile from "./p99/images/p99-taiwan-mobile.png";
import p99AsiaPacificTelecom from "./p99/images/p99-asia-pacific-telecom.png";
import intCredCard from "./gash/images/int-credit-card.png";
import intCredCardtw2 from "./gash/images/new_int-credit-card.png";
import intCredCardtw from "./gash/images/new_int-credit-card-tw.png";
import intCredCardmyr from "./gash/images/new_int-credit-card-myr.png";
import paypal from "./gash/images/paypal.png";
import kiplepay from "./gash/images/kiplepay.png";
import { getUA } from "react-device-detect";
import cookie from "react-cookies";
import KaisakuApi from "../../../controllers/kaisaku-api";
// let alipayApp = AlipayApp;
// let alipayApp = AlipayApp2p;
// let alipayApp = AlipayApp5p;
// if (process.env.apiName === "dev-jgg-api") alipayApp = AlipayApp1;

const paymentButtonData = [
    {
        provider: "antpay",
        currency: "CNY",
        paid: "BNK82204", //alipay//BNK82204
        range: {
            min: 30, max: 5000
        },
        image: AlipayLogo12
    },
    {
        provider: "antpay3",
        currency: "CNY",
        paid: "BNK82204", //alipay//BNK82204
        range: {
            min: 30, max: 5000
        },
        image: AlipayLogo12
    },
    //{
    //     provider: "antpay4",
    //    currency: "CNY",
    //     paid: "alipay",
    //     range: {
    //         min: 30, max: 304
    //     },
    //     image: AlipayLogo9
    // },
//    {
//         provider: "p99",
//         currency: "CNY",
//         paid: "BNK82204", // 设置成这个，礼包都不用调了
//         range: {
//             min: 30, max: 1800
//         },
//         image: asiaunionpay
//    },
//    {
//        provider: "gash",
//        currency: "CNY",
//        paid: "COPPAL02", //HK-PayPal USD
//        range: {
//            min: 30, max: 6000
//        },
//        image: paypal
//    },
//    {
//        provider: "gash",
//        currency: "CNY",
//        paid: "BNKRBS01", //Worldpay(RBS) USD
//        range: {
//            min: 30, max: 3650
//        },
//        image: intCredCard
//    },
//    {
//        provider: "gash",
//        currency: "HKD",
//        paid: "COPPAL02", //HK-PayPal HKD
//        range: {
//            min: 10, max: 6500
//        },
//        image: paypal
//    },
//    {
//        provider: "gash",
//        currency: "HKD",
//        paid: "BNKRBS01", //Worldpay(RBS) HKD
//        range: {
//            min: 10, max: 3980
//        },
//        image: intCredCard
//    },
//    {
//        provider: "p99", //twgash
//        currency: "TWD",
//        paid: "BNK82201", //VISA/MasterCard/JCB信用卡
//       range: {
//            min: 50, max: 30000
//        },
//        image: p99TWCreditCard
//    },
//    {
//        provider: "gash",
//        currency: "TWD",
//        paid: "COPPAL02", //HK-PayPal USD
//        range: {
//            min: 35, max: 26000
//       },
//        image: paypal
//    },
//    {
//        provider: "gash",
//        currency: "TWD",
//        paid: "BNKRBS01", //Worldpay(RBS) USD
//        range: {
//            min: 35, max: 15800
//        },
//        image: intCredCard
//    },
//    {
//        provider: "p99",
//        currency: "TWD",
//        paid: "BNK82212", //Apple Pay
//        range: {
//            min: 45, max: 30000
//        },
//        image: p99apple
//    },
//    {
//        provider: "p99", //twgash
//        currency: "TWD",
//        paid: "COPGAM09", //GASH會員遊戲帳號綁定
//        range: {
//            min: 50, max: 50000
//        },
//        image: p99GashPnt
//    },
//    {
//        provider: "p99", //twgash
//        currency: "TWD",
//        paid: "BNK80702", //國際信用卡
//        range: {
//            min: 45, max: 16000
//        },
//        image: intCredCardtw2
//    },
//    {
//        provider: "p99", //twgash
//        currency: "TWD",
//        paid: "TELCHT05", //中華電信 手機
//        range: {
//            min: 30, max: 2000
//        },
//        image: p99CHTelecom
//    },
//    {
//        provider: "p99", //twgash
//        currency: "TWD",
//        paid: "TELFET01", //遠傳電信手機
//        range: {
//            min: 30, max: 3500
//        },
//        image: p99FET
//    },
//    {
//        provider: "p99", //twgash
//        currency: "TWD",
//        paid: "TELTCC01", //台灣大哥大手機
//        range: {
//            min: 50, max: 10000
//        },
//        image: p99TWMobile
//    },
//    {
//        provider: "gash",
//        currency: "USD",
//        paid: "COPPAL02", //HK-PayPal USD
//        range: {
//            min: 1, max: 800
//        },
//        image: paypal
//    },
//    {
//        provider: "gash",
//        currency: "USD",
//        paid: "BNKRBS01", //Worldpay(RBS) USD
//        range: {
//            min: 1, max: 500
//        },
//        image: intCredCard
//   },
//    {
//        provider: "gash",
//        currency: "MYR",
//        paid: "COPWBC02", //Malaysia Bank untuk Bayar
//        range: {
//            min: 1, max: 500
//        },
//        image: kiplepay
//    },






    // {
    //     provider: "hkalipay",
    //     currency: "CNY",
    //     paid: "Alipay",
    //     range: {
    //         min: 30, max:1000
    //         // min: 10, max:700
    //         // min: 10, max:400
    //         // min: 10, max:300
    //     },
    //     image: alipayHkd2
    // },
    // {
    //    provider: "bbmslpay",
    //    currency: "CNY",
    //    paid: "bbmslpay",
    //    range: {
    //        min: 305, max: 1037
    //    },
    //    image: AlipayLogo5
    // },
    //{
    //      provider: "antpay2",
    //      currency: "CNY",
    //      paid: "Alipay"
    //      range: {
    //          min: 1200, max: 4999
    //      },
    //      image: AlipayLogo13
    //},
    // {
    //      provider: "antpay3",
    //      currency: "CNY",
    //      paid: "alipay",
    //      range: {
    //          min: 30, max: 304
    //      },
    //      image: AlipayLogo13
    //  },
    //{
    //     provider: "antpay4",
    //    currency: "CNY",
    //     paid: "alipay",
    //     range: {
    //         min: 30, max: 304
    //     },
    //     image: AlipayLogo9
    // },

    //{
    //    provider: "asiaunionpay",
    //    currency: "CNY",
    //    paid: "union_pay",
    //    range: {
    //        min: 30, max: 12000
    //    },
    // range: [{
    //     min: 30, max: 100
    // }, {
    //     min: 390, max: 400
    // }, {
    //     min: 751, max: 12000
    // }],
    //    image: asiaunionpay
    //},




    //{
    //    provider: "bbmslpay",
    //    currency: "HKD",
    //    paid: "bbmslpay",
    //    range: {
    //        min: 40, max: 5500
    //    },
    //    image: bbmsl5in1
    //},

    //{
    //    provider: "twgash",
    //    currency: "HKD",
    //    paid: "BNK80702", //國際信用卡
    //    range: {
    //        min: 10, max: 9600
    //    },
    //    image: intCredCardtw
    //},

    //{
    //    provider: "bbmslpay",
    //    currency: "TWD",
    //    paid: "bbmslpay",
    //    range: {
    //        min: 154, max: 20000
    //    },
    //    image: bbmsl5in1
    //},
    //{
    //    provider: "twgash",
    //    currency: "TWD",
    //    paid: "COPGAM10", //儲值密碼提領(E-PIN)
    //    range: {
    //        min: 50, max: 30000
    //    },
    //    image: p99epin
    //},
    // {
    //     provider: "p99",
    //     currency: "TWD",
    //     paid: "BNK82212", //Apple Pay
    //     range: {
    //         min: 45, max: 30000
    //     },
    //     image: p99apple
    // },
    // {
    //     provider: "p99",
    //     currency: "TWD",
    //     paid: "BNK82213", //Google Pay
    //     range: {
    //         min: 45, max: 30000
    //     },
    //     image: p99google
    // },
    //{
    //    provider: "twgash",
    //    currency: "TWD",
    //    paid: "BNK80801", //玉山提款卡
    //    range: {
    //        min: 50, max: 20000
    //    },
    //    image: p99WebAtm
    //},
    //{
    //    provider: "twgash",
    //    currency: "TWD",
    //    paid: "BNK80802", //ATM提款卡
    //    range: {
    //        min: 50, max: 20000
    //    },
    //    image: p99SmartPay
    //},
    //{
    //    provider: "bbmslpay",
    //    currency: "USD",
    //    paid: "bbmslpay",
    //    range: {
    //        min: 5, max: 650
    //    },
    //    image: bbmsl5in1
    //},
    //{
    //    provider: "twgash",
    //    currency: "USD",
    //    paid: "BNK80702", //國際信用卡
    //    range: {
    //        min: 1, max: 470
    //    },
    //    image: intCredCardtw
    //},

    //{
    //    provider: "bbmslpay",
    //    currency: "MYR",
    //    paid: "bbmslpay",
    //    range: {
    //        min: 24, max: 3200
    //    },
    //    image: bbmsl5in1
    //},
//{
    //    provider: "twgash",
    //    currency: "MYR",
    //    paid: "BNK80702",
    //    range: {
    //        min: 7, max: 2000
    //    },
    //    image: intCredCardmyr
    //},
    // {
    //     provider: "twgash",
    //     currency: "TWD",
    //     paid: "BNK82204",
    //     range: {
    //         min: 50, max: 20000
    //     },
    //     image: p99UnionPayTWD
    // },
    // {
    //     provider: "twgash",
    //     currency: "TWD",
    //     paid: "COPGAM09",
    //     range: {
    //         min: 50, max: 20000
    //     },
    //     image: p99GashPnt
    // },
    // {
    //     provider: "twgash",
    //     currency: "TWD",
    //     paid: "BNK80801",
    //     range: {
    //         min: 50, max: 20000
    //     },
    //     image: p99WebAtm
    // },


    // // twgash 小額電信不開
    // // {
    // //     provider: "twgash",
    // //     currency: "TWD",
    // //     paid: "TELCHT05",
    // //     range: {
    // //         min: 30, max: 2000
    // //     },
    // //     image: p99CHTelecom
    // // },
    // // {
    // //     provider: "twgash",
    // //     currency: "TWD",
    // //     paid: "TELFET01",
    // //     range: {
    // //         min: 30, max: 3000
    // //     },
    // //     image: p99FET
    // // },
    // // {
    // //     provider: "twgash",
    // //     currency: "TWD",
    // //     paid: "TELTCC01",
    // //     range: {
    // //         min: 50, max: 10000
    // //     },
    // //     image: p99TWMobile
    // // },
    // // {
    // //     provider: "twgash",
    // //     currency: "TWD",
    // //     paid: "TELSON04",
    // //     range: {
    // //         min: 30, max: 2000
    // //     },
    // //     image: p99AsiaPacificTelecom
    // // },

    // {
    //     provider: "twgash",
    //     currency: "TWD",
    //     paid: "BNK80802",
    //     range: {
    //         min: 50, max: 20000
    //     },
    //     image: p99SmartPay
    // },

    // {
    //     provider: "jpay",
    //     currency: "CNY",
    //     paid: 1,
    //     range: {
    //         min: 10,
    //         max: 15000
    //     },
    //     disabled: true,
    //     image: alipayCny
    // },
    // {
    //     provider: "jpay",
    //     currency: "HKD",
    //     paid: 1,
    //     range: {
    //         min: 50,
    //         max: 45000
    //     },
    //     image: alipayHkd
    // },
    // {
    //     provider: "jpay",
    //     currency: "CNY",
    //     paid: 5,
    //     range: {
    //         min: 101,
    //         max: 15000
    //     },
    //     disabled: true,
    //     image: unionPay
    // },
    // {
    //     provider: "jpay",
    //     currency: "CNY",
    //     paid: 4,
    //     range: {
    //         min: 1,
    //         max: 15000
    //     },
    //     disabled: true,
    //     image: cloudPay
    // },
    // {
    //     provider: "jpay",
    //     currency: "HKD",
    //     paid: 2,
    //     range: {
    //         min: 100,
    //         max: 5000
    //     },
    //     image: wechatPay
    // },
    // {
    //     provider: "ghpgpay",
    //     currency: "CNY",
    //     paid: "alipay",
    //     range: {
    //         min: 1000, max: 5000
    //     },
    //     image: ghpgAlipayZZ
    // },
    // {
    //     provider: "hipay",
    //     currency: "CNY",
    //     paid: "wapay_wechat",
    //     range: {
    //         min: 1000, max: 4000
    //     },
    //     image: wechatPay3
    // },
    // {
    //     provider: "hipay",
    //     currency: "HKD",
    //     paid: "wapay_wechat",
    //     range: {
    //         min: 1000, max: 5000
    //     },
    //     image: wechatPay3
    // },
    // {
    //     provider: "sicpay",
    //     currency: "CNY",
    //     paid: "unipock_alipay",
    //     range: {
    //         min: 10, max: 5000
    //     },
    //     image: AlipayApp
    // },
    // {
    //     provider: "sicpay",
    //     currency: "CNY",
    //     paid: "union_pay",
    //     range: {
    //         min: 10, max: 5000
    //     },
    //     image: UnionPayRMB
    // },
    // {
    //     provider: "cnalipayprivateyi",
    //     currency: "CNY",
    //     paid: "Alipay",
    //         // range: [{
    //         //     min: 50, max: 200
    //         // },{
    //         //     min: 1000, max: 2200
    //         // }],
    //     range: {
    //         min: 30, max: 600
    //     },
    //     image: Alipay2s
    // },
    // {
    //     provider: "cnalipayprivateqx",
    //     currency: "CNY",
    //     paid: "Alipay",
    //     range: [{
    //         min: 50, max: 200
    //     },{
    //         min: 1000, max: 2200
    //     }],
    //     // range: {
    //     //     min: 30, max: 1500
    //     // },
    //     image: Alipay3s
    // },
];

export class PAData {
    constructor(rawData) {
        this.provider = rawData.provider;
        this.currency = rawData.currency;
        this.paid = rawData.paid;
        this.range = rawData.range;
        this.image = rawData.image;
        this.disabled = rawData.disabled;
    }

    bbmslpayIsStop(hupaystatus) {
        if (this.provider !== "bbmslpay") return false;
        if (hupaystatus === "OK") return false;
        if (hupaystatus === undefined) return true;
        if ((this.provider === "bbmslpay") && (hupaystatus != undefined) && (hupaystatus.bbmslpaydata)) {
            if (!hupaystatus.bbmslpaydata.show)
                return true;
        }
        return false;
    }

    geoswiftpayIsStop(hupaystatus) {
        if (this.provider !== "geoswiftpay") return false;
        if (hupaystatus === "OK") return false;
        if (hupaystatus === undefined) return true;
        if ((this.provider === "geoswiftpay") && (hupaystatus != undefined) && (hupaystatus.geoswiftpaydata)) {
            if (!hupaystatus.geoswiftpaydata.show)
                return true;
        }
        return false;
    }

    antpayIsStop(hupaystatus) {
        if (this.provider !== "antpay") return false;
        if (hupaystatus === "OK") return false;
        if (hupaystatus === undefined) return false; //true
        if ((this.provider === "antpay") && (hupaystatus != undefined) && (hupaystatus.antpaydata)) {
            if (!hupaystatus.antpaydata.show)
                return false; //true
        }
        return false;
    }

    antpay2IsStop(hupaystatus) {
        if (this.provider !== "antpay2") return false;
        if (hupaystatus === "OK") return false;
        if (hupaystatus === undefined) return true;
        if ((this.provider === "antpay2") && (hupaystatus != undefined) && (hupaystatus.antpay2data)) {
            if (!hupaystatus.antpay2data.show)
                return true;
        }
        return false;
    }

    antpay3IsStop(hupaystatus) {
        if (this.provider !== "antpay3") return false;
        if (hupaystatus === "OK") return false;
        if (hupaystatus === undefined) return false; //true
        if ((this.provider === "antpay3") && (hupaystatus != undefined) && (hupaystatus.antpay3data)) {
            if (!hupaystatus.antpay3data.show)
                return false; //true
        }
        return false;
    }

    antpay4IsStop(hupaystatus) {
        if (this.provider !== "antpay4") return false;
        if (hupaystatus === "OK") return false;
        if (hupaystatus === undefined) return true;
        if ((this.provider === "antpay4") && (hupaystatus != undefined) && (hupaystatus.antpay4data)) {
            if (!hupaystatus.antpay4data.show)
                return true;
        }
        return false;
    }

    g3payIsStop(hupaystatus) {
        if (this.provider !== "g3pay") return false;
        if (hupaystatus === "OK") return false;
        if (hupaystatus === undefined) return true;
        if ((this.provider === "g3pay") && (hupaystatus != undefined) && (hupaystatus.g3paydata)) {
            if (!hupaystatus.g3paydata.show)
                return true;
        }
        return false;
    }

    heepayIsStop(hupaystatus) {
        if (this.provider !== "heepay") return false;
        if (hupaystatus === "OK") return false;
        if (hupaystatus === undefined) return true;
        if ((this.provider === "heepay") && (hupaystatus != undefined) && (hupaystatus.heepaydata)) {
            if (!hupaystatus.heepaydata.show)
                return true;
        }
        return false;
    }

    fakapayIsStop(hupaystatus) {
        if (this.provider !== "fakapay") return false;
        if (hupaystatus === "OK") return false;
        if (hupaystatus === undefined) return true;
        if ((this.provider === "fakapay") && (hupaystatus != undefined) && (hupaystatus.fakapaydata)) {
            if (!hupaystatus.fakapaydata.show)
                return true;
        }
        return false;
    }

    hkalipayIsStop(hupaystatus) {
        if (this.provider !== "hkalipay") return false;
        if (hupaystatus === "OK") return false;
        if (hupaystatus === undefined) return true;
        if ((this.provider === "hkalipay") && (hupaystatus != undefined) && (hupaystatus.scanpaydata)) {
            if (!hupaystatus.scanpaydata.show)
                return true;
        }
        return false;
    }

    hkalipay2IsStop(hupaystatus) {
        if (this.provider !== "hkalipay2") return false;
        if (hupaystatus === "OK") return false;
        if (hupaystatus === undefined) return true;
        if ((this.provider === "hkalipay2") && (hupaystatus != undefined) && (hupaystatus.scanpay2data)) {
            if (!hupaystatus.scanpay2data.show)
                return true;
        }
        return false;
    }

    goallpayIsStop(goallpaystatus) {
        if (this.provider !== "allpayx") return false;
        if (goallpaystatus === "OK") return false;
        if (goallpaystatus === undefined) return true;
        if ((this.provider === "allpayx") && (goallpaystatus != undefined) && (goallpaystatus.goallpaydata)) {
            if (!goallpaystatus.goallpaydata.show)
                return true;
        }
        return false;
    }

    geounionpayIsStop(geounionpaystatus) {
        if (this.provider !== "geounionpay") return false;
        if (geounionpaystatus === "OK") return false;
        if (geounionpaystatus === undefined) return true;
        if ((this.provider === "geounionpay") && (geounionpaystatus != undefined) && (geounionpaystatus.geounionpaydata)) {
            if (!geounionpaystatus.geounionpaydata.show)
                return true;
        }
        return false;
    }

    hipayIsStop(hupaystatus) {
        if (this.provider !== "hipay") return false;
        if (hupaystatus === "OK") return false;
        if (hupaystatus === undefined) return true;
        if ((this.provider === "hipay") && (hupaystatus != undefined) && (hupaystatus.hipaydata)) {
            if (!hupaystatus.hipaydata.show)
                return true;
        }
        return false;
    }

    p99IsStop(hupaystatus) {
        if (this.provider !== "p99") return false;
        if (hupaystatus === "OK") return false;
        if (hupaystatus === undefined) return true;
        if ((this.provider === "p99") && (hupaystatus != undefined) && (hupaystatus.p99data)) {
            if (!hupaystatus.p99data.show)
                return true;
        }
        return false;
    }

    gashIsStop(hupaystatus, paid) {
        if (this.provider !== "gash") return false;
        if (hupaystatus === "OK") {
            console.log("OKKKK");
            return false;
        }
        if (hupaystatus === undefined) return true;
        if ((this.provider === "gash") && (hupaystatus != undefined) && (hupaystatus.gashdata)) {
            console.log("OKKKK111");
            if (!hupaystatus.gashdata.show) {
                return true;
            } else {
                if (paid === "BNKRBS01") {
                    if (!hupaystatus.gashdata.BNKRBS01) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    twGashIsStop(hupaystatus) {
        if (this.provider !== "twgash") return false;
        if (hupaystatus === "OK") return false;
        if (hupaystatus === undefined) return true;
        if ((this.provider === "twgash") && (hupaystatus != undefined) && (hupaystatus.twgashdata)) {
            if (!hupaystatus.twgashdata.show)
                return true;
        }
        return false;
    }

    hupayIsStop(hupaystatus) {
        if (this.currency !== "CNY") return false;
        if (hupaystatus === "OK") return false;
        if (hupaystatus === undefined) return true;
        if ((this.provider === "hupay") && (hupaystatus != undefined) && (hupaystatus.hupaydata)) {
            console.log(hupaystatus);
            if (hupaystatus.hupaydata.hupay1 >= 26000)
                return true;
        }
        if ((this.provider === "hupay2") && (hupaystatus != undefined) && (hupaystatus.hupaydata)) {
            if (hupaystatus.hupaydata.hupay2 >= 26000)
                return true;
        }
        if ((this.provider === "hupay3") && (hupaystatus != undefined) && (hupaystatus.hupaydata)) {
            if (hupaystatus.hupaydata.hupay3 >= 26000)
                return true;
        }
        if ((this.provider === "hupay4") && (hupaystatus != undefined) && (hupaystatus.hupaydata)) {
            if (hupaystatus.hupaydata.hupay4 >= 26000)
                return true;
        }
        if ((this.provider === "hupay5") && (hupaystatus != undefined) && (hupaystatus.hupaydata)) {
            if (hupaystatus.hupaydata.hupay5 >= 26000)
                return true;
        }
        if ((this.provider === "hupay6") && (hupaystatus != undefined) && (hupaystatus.hupaydata)) {
            if (hupaystatus.hupaydata.hupay6 >= 26000)
                return true;
        }
        return false;
    }

    /**
     * Whether the button should be greyed out
     * @param price
     * @returns {boolean}
     */
    outOfRange(price, hupaystatus, currency) {
        // let bStatus = this.hupayIsStop(hupaystatus);
        // if (bStatus === true) return true;
        let bStatus = this.p99IsStop(hupaystatus);
        if (bStatus === true) return true;
        bStatus = this.gashIsStop(hupaystatus, this.paid);
        if (bStatus === true) return true;
        bStatus = this.twGashIsStop(hupaystatus);
        if (bStatus === true) return true;
        bStatus = this.hkalipayIsStop(hupaystatus);
        if (bStatus === true) return true;
        bStatus = this.hkalipay2IsStop(hupaystatus);
        if (bStatus === true) return true;
        bStatus = this.goallpayIsStop(hupaystatus);
        if (bStatus === true) return true;
        bStatus = this.fakapayIsStop(hupaystatus);
        if (bStatus === true) return true;
        bStatus = this.geoswiftpayIsStop(hupaystatus);
        if (bStatus === true) return true;
        bStatus = this.heepayIsStop(hupaystatus);
        if (bStatus === true) return true;
        bStatus = this.g3payIsStop(hupaystatus);
        if (bStatus === true) return true;
        bStatus = this.antpayIsStop(hupaystatus);
        if (bStatus === true) return true;
        bStatus = this.antpay2IsStop(hupaystatus);
        if (bStatus === true) return true;
        bStatus = this.antpay3IsStop(hupaystatus);
        if (bStatus === true) return true;
        bStatus = this.antpay4IsStop(hupaystatus);
        if (bStatus === true) return true;
        bStatus = this.bbmslpayIsStop(hupaystatus);
        if (bStatus === true) return true;
        bStatus = this.geounionpayIsStop(hupaystatus);
        if (bStatus === true) return true;
        // bStatus = this.hipayIsStop(hupaystatus);
        // if (bStatus === true) return true;
        if (this.disabled) {
            return true;
        }
        // ||(this.provider === "hkalipay") || (this.provider === "hkalipay2")
        if ((this.provider === "fakapay") || (this.provider === "cnalipayprivate") || (this.provider === "cnalipay") || (this.provider === "cnalipay2")
            || (this.provider === "spglobalpay")
        ) {
            const gameId = cookie.load("_gameId");
            const gameId1 = "aac53ce9-f03c-4e71-953b-df9ec022a837";
            const gameId2 = "aac53ce9-f03c-4e71-953b-df9ec022a837";
            const gameId3 = "b15402f9-6bca-4c17-abed-5ba00f7dd0c7";
            const gameId4 = "de7679d9-c5b5-47ae-84f5-ad96be7d612e";
            const gameId5 = "f552d32d-f73a-46c6-ba20-42098ed27c1c";
            const gameId6 = "87cd854c-f5f4-4018-a57d-6d49007931db";
            // if (((gameId1 === gameId) || (gameId2 === gameId) || (gameId3 === gameId) || (gameId4 === gameId)
            //     || (gameId5 === gameId) || (gameId6 === gameId))) {
            //     return true;
            // }
        }
        if (this.range) {
            if (this.range.length === undefined) {
                // if ((currency === "CNY" || currency === "RMB")
                //     && (this.provider === "p99") && (this.paid === "BNK82204")) {
                //     console.log(this.paid);
                //     // return price < this.range.min || price > (this.range.max / 5);
                //     return price < 30 || price > 1000;
                // }
                // if ((currency === "CNY" || currency === "RMB")
                //     && (this.provider === "twgash") && (this.paid === "BNK82204")) {
                //     console.log(this.paid);
                //     // return price < this.range.min || price > (this.range.max / 5);
                //     return price < 30 || price > 4500;
                // }
                return price < this.range.min || price > this.range.max;
            } else {
                console.log("11111");
                console.log(this.provider);
                for (let i = 0; i < this.range.length; i++) {
                    const item = this.range[i];
                    console.log(item);
                    console.log(price < item.min || price > item.max);
                    if ((price < item.min || price > item.max) === false) {
                        return false;
                    }
                }
                return true;
            }
        }
        console.log("ddd");
        return false;
    }



    shouldShow(currency, hupaystatus) {
        let bStatus = true;
        if ((currency === "CNY") || (currency === "TWD")) {
            // if (this.provider.indexOf("hupay") >= 0){
            //     bStatus = !this.hupayIsStop(hupaystatus);
            // }
            if (this.provider === "p99") {
                bStatus = !this.p99IsStop(hupaystatus);
            }
            // if (this.provider === "hipay") {
            //     bStatus = !this.hipayIsStop(hupaystatus);
            // }
            // if (this.provider === "fakapay") {
            //     bStatus = !this.fakapayIsStop(hupaystatus);
            // }
            if (this.provider === "geoswiftpay") {
                bStatus = !this.geoswiftpayIsStop(hupaystatus);
            }
            if (this.provider === "heepay") {
                bStatus = !this.heepayIsStop(hupaystatus);
            }
            if (this.provider === "g3pay") {
                bStatus = !this.g3payIsStop(hupaystatus);
            }
            if (this.provider === "antpay") {
                bStatus = !this.antpayIsStop(hupaystatus);
            }
            if (this.provider === "antpay2") {
                bStatus = !this.antpay2IsStop(hupaystatus);
            }
            if (this.provider === "antpay3") {
                bStatus = !this.antpay3IsStop(hupaystatus);
            }
            if (this.provider === "antpay4") {
                bStatus = !this.antpay4IsStop(hupaystatus);
            }
        }
        if (this.provider === "hkalipay") {
            bStatus = !this.hkalipayIsStop(hupaystatus);
        }
        if (this.provider === "hkalipay2") {
            bStatus = !this.hkalipay2IsStop(hupaystatus);
        }
        if (this.provider === "bbmslpay") {
            bStatus = !this.bbmslpayIsStop(hupaystatus);
        }
        if (this.provider === "geounionpay") {
            bStatus = !this.geounionpayIsStop(hupaystatus);
        }
        if (this.provider === "allpayx") {
            bStatus = !this.goallpayIsStop(hupaystatus);
        }
        if (this.provider === "gash") {
            bStatus = !this.gashIsStop(hupaystatus, this.paid);
        }
        if (this.provider === "twgash") {
            bStatus = !this.twGashIsStop(hupaystatus);
        }

        if (this.isEdgeCase(currency)) {
            return true;
        }
        return (currency === this.currency) && (bStatus);
    }

    /**
     * Determine whether or not this button should show even if it's otherwise filtered out
     * @param currency
     * @returns {boolean|boolean}
     */
    isEdgeCase(currency) {
        if (currency === "CNY" || currency === "RMB") {
            switch (this.provider) {
                case "jpay":
                    return this.paid === 1 && this.currency === "HKD";
                // case "p99":
                //     return this.paid === "BNK82204";
                // case "twgash":
                //     return this.paid === "BNK82204";
                default:
                    return false;
            }
        }
    }

    static compare(a, b, price, hupaystatus, currency) {
        if (b.outOfRange(price, hupaystatus, currency)) {
            return -1;
        } else {
            return 0;
        }
    }
}

export function getValidButtonData(currency, price, hupaystatus) {
    // 读取配置 加到 paymentButtonData中
    // const res = await KaisakuApi.getPaymentButtonData();
    return getPaymentButtonData().map(data => new PAData(data)).filter(data => data.shouldShow(currency, hupaystatus)).sort((a, b) => PAData.compare(a, b, price, hupaystatus, currency));
}

export function hasValidButton(currency, price) {
    return getPaymentButtonData().map(data => new PAData(data)).filter(data => data.shouldShow(currency, "OK")).some(data => !data.outOfRange(price, "OK", currency));
}

function getPaymentButtonData() {
    const userId = cookie.load("_buttonDataUID");
    let paymentButtonData1 = paymentButtonData;
    let paymentButtonData2 = JSON.parse(sessionStorage.getItem("buttonData"));
    paymentButtonData2 = paymentButtonData2.sort(function (a, b) {
        if (!a.sort && a.sort !== 0) a.sort = 100;
        if (!b.sort && b.sort !== 0) b.sort = 100;
        return a.sort - b.sort
    });
    let newButtonData = [];
    let sort = paymentButtonData2.length;
    for (let i = 0; i < paymentButtonData1.length; i++) {
        let newitem = paymentButtonData1[i];
        newitem.sort = ++sort;
        newitem = updateItem(newitem, paymentButtonData2);
        if (newitem) {
            // {
            //     provider: "bbmslpay",
            //     currency: "CNY",
            //     paid: "bbmslpay",
            //     range: {
            //         min: 300, max: 1000
            //     },
            //     image: AlipayLogo5
            // },
            if ((newitem.provider === "bbmslpay") && (newitem.currency === "CNY")){
                if (isTestUid(userId)){
                    newitem.range.min = 30;
                } 
            }
            if ((newitem.provider === "geoswiftpay") && (newitem.currency === "CNY")){
                if (isDaRUid(userId)){
                    newitem.range.max = 4000;
                } 
            }
            newButtonData.push(newitem);
        }
    }
    console.log("1111");
    console.log(newButtonData.sort(function (a, b) {
        if (!a.sort && a.sort !== 0) a.sort = 100;
        if (!b.sort && b.sort !== 0) b.sort = 100;
        return a.sort - b.sort
    }));
    return newButtonData.sort(function (a, b) {
        if (!a.sort && a.sort !== 0) a.sort = 100;
        if (!b.sort && b.sort !== 0) b.sort = 100;
        return a.sort - b.sort
    });
}
// 如果是测试帐号，给开到30的最小额度
function isTestUid(userId){
    const userIds = [
        "c66a5541-ed54-4e8f-9356-51dc367c753f",
        "134736fc-0619-43d6-80ec-c64ad5f4521f"
    ];
    if ((userId) && (userIds.includes(userId))){
        return true;
    }
    return false;
}
// 如果是大R bbmslpay給開到4000额度
function isDaRUid(userId){
    const userIds = [
        "c66a5541-ed54-4e8f-9356-51dc367c753f",
        "38ea1f77-b266-490e-9a25-8ed680bc7433"
    ];
    if ((userId) && (userIds.includes(userId))){
        return true;
    }
    return false;
}

function updateItem(newitem, paymentButtonData2) {
    const datas = paymentButtonData2.filter(o => o.provider === newitem.provider && o.paid === newitem.paid && o.currency === newitem.currency);
    if (datas.length === 1) {
        const item = datas[0];
        if (item.status) {
            newitem.range = item.range;
            newitem.sort = item.sort;
            return newitem;
        } else {
            return null;
        }
    }
    return newitem;
}